<template>
  <div class="container" v-title :data-title="$t('i18n.repairRecord')">
    <div id="outer-title">{{ $t("i18n.repairRecord") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.repairRecord')"
      v-model="dialogAddVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="addColumns"
        @onSubmit="onSubmit"
        @resetForm="dialogOnEvent('dialogAddVisible', false)"
      ></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "PhoneRecord",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "tagNumber",
          label: "tagNo",
          align: "center",
        },
        {
          prop: "threeNumber",
          label: "nineNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "cellName",
          label: "residenceName",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "locate",
          label: "buildingNo",
          align: "center",
        },
        {
          prop: "isTrapped",
          label: "trappedOrNot",
          align: "center",
          formatter: (row) => {
            if (row.isTrapped == 0 || row.isTrapped == 1) {
              return row.isTrapped == 0 ? "否" : "是";
            } else {
              return row.isTrapped;
            }
          },
        },
        {
          prop: "name",
          label: "serviceStaff",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "phone",
          label: "serviceCall",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            class: "add",
            icon: "el-icon-plus",
            show: proxy.$defined.btnPermission("新增报修记录"),
            type: "outer",
            method: () => {
              dialogOnEvent("dialogAddVisible", true);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      dialogAddVisible: false,
      addColumns: [
        {
          prop: "locate",
          label: "buildingNo",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "phone",
          label: "serviceCall",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isTrapped",
          label: "trappedOrNot",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "trapNumber",
          label: "trappedNumber",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isSpecial",
          label: "AgedKidsPregnantInCar",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isInjured",
          label: "anyoneInjured",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "tagNumber",
          label: "tagNo",
          type: "input",
        },
        {
          prop: "threeNumber",
          label: "nineNo",
          type: "input",
        },
        {
          prop: "cellName",
          label: "residenceName",
          type: "input",
        },
        {
          prop: "name",
          label: "serviceStaff",
          type: "input",
        },
        {
          prop: "station",
          label: "stopOnFloor",
          type: "input",
        },
        {
          prop: "isStop",
          label: "elevatorStoppedOrNot",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
        },
        {
          prop: "faultDesc",
          label: "faultDescription",
          type: "input",
        },
      ],
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.rescue.recordsPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const dialogOnEvent = (dialog, bool) => {
      state[dialog] = bool;
    };

    const onSubmit = async (row) => {
      await proxy.$api.rescue.addrecords(row);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      dialogOnEvent("dialogAddVisible", false);
      init(state.formInline);
    };

    return {
      ...toRefs(state),
      dialogOnEvent,
      init,
      onSubmit,
    };
  },
};
</script>
